/* here is the program css */
div.container.forprogramhome {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 7px;
}

.container {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}

/* avatar style */

/* banner style */
.banner {
  color: white;
  text-align: center;
  height: 100vh;
  background-size: cover !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner h1 {
  font-size: 50px;
  font-weight: bold;
  text-shadow: 0px 1px rgba(0, 0, 0, 0.2);
}

.banner p {
  font-size: 25px;
  font-weight: lighter;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 50px;
}

.banner .btn {
  margin-top: 20px;
}

/* button style*/
.btn-treehouse {
  color: white;
  padding: 25px 35px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 20px;
  background: #6ad58b;
  transition: background 0.3s ease;
}

.btn-treehouse:hover {
  opacity: 1;
  background: #3ac162;
  text-decoration: none;
  color: white;
}

/* cards style*/
.cards {
  display: flex;
}

.cardhome {
  flex: 1 1 auto;
  margin: 3px;
  border-radius: 1px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

ho .cardhome:hover {
  margin-top: -5px;
}

.card-body-program-home {
  height: 180px;
  background-size: cover;
  color: white;
  position: relative;
}

.card-user {
  position: absolute;
  right: 10px;
  top: 10px;
}

.card-category {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: lighter;
  opacity: 0.9;
}

.card-description {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.card-description h2 {
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: bold;
}

.card-description p {
  font-size: 14px;
  font-weight: lighter;
  opacity: 0.9;
}

.card-link {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background: black;
  opacity: 0;
}

.card-link:hover {
  opacity: 0.1;
}

.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.card-footer p {
  color: rgba(0, 0, 0, 0.4);
  font-weight: lighter;
  font-size: 12px;
}

.card-footer .controls {
  display: flex;
  align-items: center;
}

.card-footer .controls i {
  padding: 0 10px;
  color: black;
  opacity: 0.2;
}

.card-footer .controls i:hover {
  opacity: 0.4;
}

/* program css ends here */

.fa.fa-user,
.fa.fa-clock-o {
  padding-right: 10px;
  color: #d61010;
  font-size: 11px;
}

.post-image {
  height: 250px;
  overflow: hidden;
}

.post-title {
  font-weight: 900;
  font-size: xx-large;
  font-style: italic;
  text-decoration: none;
}

.post-description {
  font-size: 15px;
  padding-top: 20px;
}

#my_with {
  display: none;
}

.MuiCardHeader-content span {
  color: black;
  font-size: 15px;
  font-weight: bolder;
}

.MuiGridListTileBar-title {
  font-size: 1.7em !important;
}

.card-body {
  padding: 0px !important;
}

.text-center {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.card-img {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  text-align: left !important;
}

.card-title {
  margin-bottom: 0.3rem;
}

.cat {
  display: inline-block;
  margin-bottom: 1rem;
}

.fa-users {
  margin-left: 1rem;
}

.card-footer {
  font-size: 0.8rem;
}

@import url("https://fonts.googleapis.com/css?family=Noto+Serif:400,700");

.comment-container {
  width: 80%;
  margin: 2rem auto;
}

a {
  color: #c40030;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

.v-btn {
  align-items: center;
  border-radius: 2px;
  display: inline-flex;
  height: 36px;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  margin: 6px 8px;
  min-width: 88px;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  position: relative;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 16px;
}

.v-btn:before {
  border-radius: inherit;
  color: inherit;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0.12;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}

.v-btn:focus,
.v-btn:hover {
  position: relative;
}

.v-btn:focus:before,
.v-btn:hover:before {
  background-color: currentColor;
}

.v-btn__content {
  align-items: center;
  border-radius: inherit;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  width: inherit;
}

.v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
  will-change: box-shadow;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.v-btn:not(.v-btn--depressed):not(.v-btn--flat):active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.avatar {
  width: 50px;
  height: 50px;
  margin-left: -42px;
  border-radius: 50%;
}

.v-avatar {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.v-avatar img {
  border-radius: 50%;
  display: inline-flex;
  height: inherit;
  width: inherit;
  object-fit: cover;
}

.v-card {
  text-decoration: none;
}

.v-card> :first-child:not(.v-btn):not(.v-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.v-card> :last-child:not(.v-btn):not(.v-chip) {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.v-sheet {
  display: block;
  border-radius: 2px;
  position: relative;
}

.v-dialog__container {
  display: inline-block;
  vertical-align: middle;
}

.elevation-2 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

:after,
:before {
  text-decoration: inherit;
  vertical-align: inherit;
}

* {
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}

a:active,
a:hover {
  outline-width: 0;
}

button {
  font: inherit;
  overflow: visible;
  text-transform: none;
  background-color: transparent;
  border-style: none;
  color: inherit;
}

[type="button"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}

[type="button"]::-moz-focus-inner,
button:-moz-focusring {
  outline: 0;
  border: 0;
}

button,
html [type="button"] {
  -webkit-appearance: button;
}

img {
  border-style: none;
}

::-ms-clear,
::-ms-reveal {
  display: none;
}

.headline {
  font-weight: 400;
  letter-spacing: normal !important;
  font-size: 24px !important;
  line-height: 32px !important;
}

.title {
  font-size: 20px !important;
  font-weight: 700;
  line-height: 1 !important;
  letter-spacing: 0.02em !important;
}

.caption {
  font-weight: 400;
  font-size: 12px !important;
}

.theme--light.v-btn {
  color: rgba(0, 0, 0, 0.87);
}

.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #f5f5f5;
}

.theme--light .v-card {
  box-shadow: rgba(0, 0, 0, 0.11) 0 15px 30px 0px,
    rgba(0, 0, 0, 0.08) 0 5px 15px 0 !important;
}

.theme--light.application .v-card {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
  color: #102c3c !important;
}

.theme--light.v-card,
.theme--light.v-sheet {
  background-color: #fff;
  border-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}

a,
a:hover {
  text-decoration: none !important;
}

.wrapper {
  overflow: auto;
}

.answers {
  padding-left: 64px;
}

.comment {
  overflow-y: auto;
  margin-left: 32px;
  margin-right: 16px;
}

.comment p {
  font-size: 14px;
  margin-bottom: 7px;
}

.displayName {
  margin-left: 24px;
}

.actions {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
}

.google-span[data-v-35838f51] {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}

.google-button[data-v-35838f51] {
  background-color: #fff;
  height: 40px;
  margin: 0;
}

.headline {
  margin-left: 32px;
}

.sign-in-wrapper {
  margin-top: 16px;
  margin-left: 32px;
}

.error-message {
  font-style: oblique;
  color: #c40030;
}

::-moz-selection,
::selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}

.card,
.card {
  padding: 32px 5px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
}

.application a,
[type="button"],
button {
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .comment-container {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .col-12 {
    position: relative;
    width: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.comName {
  width: 70%;
}

table {
  width: 100%;
  table-layout: fixed;
}

.dateTable {
  width: 30%;
}

.nameTag {
  font-weight: "bold";
}
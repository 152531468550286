.videos-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    /* padding: 60px */
}

.videos-grid-video {}

.videos-grid-video>iframe {
    height: 278px;
}

@media (max-width: 600px) {
.videos-grid {
        display: inline;
        grid-column-gap: 20px;
    }
}
/* Menu header started here  */

/* header */

/* Menu header stopped here  */

/* home image slider started here  */

.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.slide.animateOut p {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.slide.animateOut section span {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.slide.animateOut p {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.slide.animateOut section span {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

body {
  /* font-family: "Lato", sans-serif;
  box-sizing: border-box;
  background-color: #f5f5f5;
  font-size: 1.1em; */
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAL0lEQVQYV2NkIBIwEqHOGKjmLDEKwWbhUwg2CWYjRSaimESRiVhNIstEvCbBTAQAl5IFCylWpesAAAAASUVORK5CYII=)
    repeat;
}
.makeStyles-root-1 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAL0lEQVQYV2NkIBIwEqHOGKjmLDEKwWbhUwg2CWYjRSaimESRiVhNIstEvCbBTAQAl5IFCylWpesAAAAASUVORK5CYII=)
    repeat;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  background: #000000;
  z-index: 10;
}

header section {
  max-width: 1300px;
  margin: auto;
  padding: 20px;
  background: #ffd800;
}

@media (max-width: 1515px) {
  header section {
    max-width: 100%;
  }
}

header section .logo {
  transform: translateX(-87px);
}

header section a {
  float: right;
  padding: 3px 0;
}

* {
  outline: none;
}

button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: none;
  background: #ffd800;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: cursor;
}

button:hover {
  color: #ffffff;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper {
  position: relative;
  height: 95vh;
  overflow: hidden;
}

.slide {
  /* min-height: 100%;
  min-width: 1024px; */

  /* Set up proportionate scaling */
  /* width: 100%;
  height: auto; */

  /* Set up positioning */
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: url("https://www.flaticon.com/svg/static/icons/svg/32/32213.svg")
    no-repeat center center / 16px;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
  background: url("https://www.flaticon.com/svg/static/icons/svg/32/32213.svg")
    no-repeat center center / 16px;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: -10px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -10px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

/* body {
  margin: 0;
  font-family: Helvetica, sans-serif;
  background-color: #fffceb;
} */

a {
  color: #000;
}

/* full image slider ended her  */

/* Events slider starts here */

.slick-track {
  display: flex;
  height: 600px;
  padding-top: 68px;

  margin-top: -70px;
}
.slick-slider {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  box-shadow: 0px 0px 8px 8px rgb(161, 159, 159) !important;
  -webkit-box-shadow: 0px 0px 5px 5px rgb(165, 163, 163) !important;
  -moz-box-shadow: 0px 0px 8px 8px rgb(128, 123, 123) !important;
  margin-top: 30px;
}
/* .row {
  margin-left: -80px;
  padding-left: 20px;
  padding-right: 20px;
} */
.slick-slide {
  padding-right: 10px;

  /*
  margin-top: 30px;
  display: block !important;
  float: left !important; */
}

.slick-slide:last-child {
  padding-right: 0;
}
.slick-slide img {
  display: block;
}
.slick-list {
  margin: 0 15px;
}

.slick-prev {
  width: 50px !important;
  left: 0 !important;
  top: 20% !important;
  position: absolute !important;
  height: 50px !important;
  background: tomato !important;
  z-index: 1 !important;
  border-radius: 50% !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !important;
}
.container {
  margin: 0 auto;
  padding: 40px;
  width: 80%;
  color: #333;
  background: #419be0;
}

.slick-next {
  width: 50px !important;
  right: 0 !important;
  top: 20% !important;
  position: absolute !important;
  height: 50px !important;
  background: tomato !important;
  z-index: 1 !important;
  border-radius: 50% !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !important;
}

.slick-prev:focus,
.slick-prev:hover,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: tomato;
}
.product-actions {
  display: flex;
  justify-content: space-between;
}
/* .hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 100px;
  height: 100px;
  overflow: hidden;
} */



/* product slider starts here  */
/* body {
  padding: 2rem 0rem;
} */
.card {
  margin-top: 20px;
  padding-top: 4px;
  /* padding-bottom: 4px; */
}

.card-img {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.card-title {
  margin-bottom: 0.3rem;
  margin-top: -45px;
  text-align: center;
  font-weight: bold;
  font-size: 3.3vh;
}

/* .startingclass,
.endingclass {
  background-color: red
} */

@media screen and (max-device-width:640px),
screen and (max-width:640px)  {
  .startingclass,
  .endingclass {
    font-size: 1.5vh !important;
  }

  p.card-text.text-center {
    font-size: 1.9vh
  }


}

.cat {
  display: inline-block;
  margin-bottom: 1rem;
}
.startingclass,
.endingclass {
  -webkit-animation: background 5s cubic-bezier(1, 0, 0, 1) infinite;
  animation: background 5s cubic-bezier(1, 0, 0, 1) infinite;
  font-size: 13.5px;
  font-weight: bold;
  border: solid LightGrey 2px;
}

@-webkit-keyframes background {
  0% {
    background-color: #f99;
  }
  33% {
    background-color: #9f9;
  }
  67% {
    background-color: #99f;
  }
  100% {
    background-color: #f99;
  }
}

@keyframes background {
  0% {
    background-color: #f99;
  }
  33% {
    background-color: #9f9;
  }
  67% {
    background-color: #99f;
  }
  100% {
    background-color: #f99;
  }
}

/* .fa-users {
  margin-left: 1rem;
} */

/* .card-footer {
  font-size: 0.8rem;
} */

/* this is event slider link  */

.btn-grad {
  background-image: linear-gradient(
    to right,
    #c04848 0%,
    #480048 51%,
    #c04848 100%
  );
}
.btn-grad {
  margin: 10px;
  padding: 6px 5px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}




.container {
  /* border-radius: 5px; */
  background-color: #f2f2f2;
  padding: 2px;
}

div.dateevent {
  color: #f70d04;
}

.livestartedclass,
.liveendingclass {
  color: black;
  font-size: 1rem;
  font-weight: bold;
}

.makeStyles-avatar-8 {
  width: 7vh !important;
  height: 7vh !important;
  font-weight: bolder;
  font-size: 3vh !important;
}

ul {
  margin: 0px;
  padding: 0px;
}

/* text for events starts here */

.eventlist {
  background-color: rgb(92, 2, 2);
  color: white;
  /* padding: 10px; */
  text-align: center;
  font-size: 3.6vmin;
  font-style: italic;
  font-weight: bolder;
}

.programlist {
  background-color: rgb(92, 2, 2);
  color: white;
  /* padding: 10px; */
  text-align: center;
  font-size: 3.6vmin;
  font-style: italic;
  font-weight: bolder;
}
/* and ends here  */
.footer-section {
  background: #151414;
  position: relative;
  padding: 20px;
  margin-top: 50px;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: #dd4b39;
}
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: #ff5e14;
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
